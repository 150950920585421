import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Col,
  Container,
  Input,
  FormGroup,
  Card,
  Row,
  CardHeader,
  Button,
  CardBody,
  Form,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../../state/index";
import { useSelector, useDispatch } from "react-redux";
import { LOADING } from "../../Constants/constants";
import Header from "components/Headers/Header.js";
import Loader from "react-loader-spinner";

export default function Notification() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const newpolicyData = useSelector((state) => state.allAuth.policyData);
  const isLoading = useSelector((state) => state.allAuth.isLoading);

  const { POST_NOTIFICATION } = bindActionCreators(actionsCreators, dispatch);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  async function postNotification(event) {
    event.preventDefault();
    if (title !== "" && body !== "") {
      let objdata = {
        title: title,
        description: body,
      };
      try {
        const userData = await POST_NOTIFICATION(objdata);
        if (userData.data.isSuccess) {
          addToast(userData?.data?.message, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(userData?.data?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      addToast("Fill all the feilds", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }
  return (
    <>
      <Header />
      {isLoading ? (
        <div className="aling-items-center mt-5">
          <Loader type="Grid" color="#8DBF26" height={60} width={60} />
        </div>
      ) : (
        <Container>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow mt-4">
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Send Notification
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label>Title</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Title"
                            defaultValue={""}
                            type="text"
                            required
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">About me</h6> */}
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Description</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Description"
                        rows="4"
                        required
                        defaultValue={""}
                        type="textarea"
                        onChange={(e) => setBody(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="pl-lg-4">
                    <Button
                      className="mr-4"
                      color="success"
                      onClick={(e) => postNotification(e)}
                      size="md"
                      type="submit"
                    >
                      Send
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          {/* <Col lg="12" md="12">
          <h2>{data?.title}</h2>
          <p>{data?.body}</p>
        </Col> */}
        </Container>
      )}
    </>
  );
}
