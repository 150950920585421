import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

import "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAC4tmzEBjk2a_xg6wm2rgj6p1Nhy-NACY",
  authDomain: "backyard-bet.firebaseapp.com",
  databaseURL: "https://backyard-bet-default-rtdb.firebaseio.com",
  projectId: "backyard-bet",
  storageBucket: "backyard-bet.appspot.com",
  messagingSenderId: "571873267978",
  appId: "1:571873267978:web:8ca22ffed65faf57072af3",
  measurementId: "G-4P3MV8DBYQ",
};

// const storage = firebase.storage();

// export { storage, firebase as default };

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

export const auth = getAuth(app);
