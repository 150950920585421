import * as React from "react";
import { Button } from "reactstrap";
import downloadCsv from "download-csv";
import moment from "moment";

const UserExport = ({ data }) => {
  const exportFileName = "UserData.csv";
  const downloadArrayData = () => {
    const datas = data?.map((row) => {
      return {
        Id: row.id,
        FirstName: row.firstName,
        LastName: row.lastName,
        FullName: row.fullName,
        Payment_Mathod_Enable: row.isBank,
        IsActive: row.isActive,
        Profile_Picture: row.profilePicture,
        Created_On_Date: moment(row.createdOnDate).format("MM/DD/YYYY"),
      };
    });

    downloadCsv(datas, exportFileName);
  };

  return (
    <>
      <Button
        title="Export Excel"
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary mb-2"
        onClick={() => downloadArrayData()}
      >
        Download UserData
      </Button>
    </>
  );
};

export default UserExport;
