import moment from "moment";
export const SortArray = (arr, key, type) => {
  var resultantArray = arr?.sort((a, b) => {
    switch (type) {
      case "acs":
        return a[key].toString().localeCompare(b[key].toString(), "en", {
          ignorePunctuation: true,
        });
      case "dsc":
        return b[key].toString().localeCompare(a[key].toString(), "en", {
          ignorePunctuation: true,
        });
      case "date":
        return (
          new Date(moment(a?.createdOnDate).format("MM/DD/YYYY")) -
          new Date(moment(b?.createdOnDate).format("MM/DD/YYYY"))
        );
      case "newdate":
        return (
          new Date(moment(b?.createdOnDate).format("MM/DD/YYYY")) -
          new Date(moment(a?.createdOnDate).format("MM/DD/YYYY"))
        );
      case "int":
        return a?.data[key]
          .toString()
          .localeCompare(b?.data[key].toString(), undefined, {
            numeric: true,
          });
      default:
        return arr;
        break;
    }
  });
  return resultantArray;
};
