import {
  LOADING,
  GET_ALL_USERS,
  GET_PRIVACY_POLICYS,
  GET_TERMS_CONDITION,
  GET_ALL_SETTINGS,
  GET_ALL_GROUPS,
  HEADER_LOADING,
  GET_ALL_USERS_DETAILS,
  GET_WEEKLY_SIGNUP,
  GET_ALL_ONLINE,
  GRAPHLOADING,
} from "../../Constants/constants";

const initialState = {
  isLoading: false,
  headerLoading: false,
  graphLoading: false,
  userData: [],
  userDetails: [],
  allGroups: [],
  policyData: [],
  termsData: [],
  allSettings: [],
  weeklyData: [],
  onlineUsers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, isLoading: action.payload };

    case HEADER_LOADING:
      return { ...state, headerLoading: action.payload };

    case GRAPHLOADING:
      return { ...state, graphLoading: action.payload };

    case GET_ALL_USERS:
      return { ...state, userData: action.payload };

    case GET_ALL_USERS_DETAILS:
      return { ...state, userDetails: action.payload };

    case GET_ALL_GROUPS:
      return { ...state, allGroups: action.payload };

    case GET_PRIVACY_POLICYS:
      return { ...state, policyData: action.payload };

    case GET_TERMS_CONDITION:
      return { ...state, termsData: action.payload };

    case GET_ALL_SETTINGS:
      return { ...state, allSettings: action.payload };

    case GET_WEEKLY_SIGNUP:
      return { ...state, weeklyData: action.payload };

    case GET_ALL_ONLINE:
      return { ...state, onlineUsers: action.payload };

    default:
      return state;
  }
};

export default reducer;
