import { getApi, postApi, putApi, deleteApi } from "../../api";
import {
  LOADING,
  GET_PRIVACY_POLICYS,
  GET_TERMS_CONDITION,
  GET_ALL_SETTINGS,
  GET_ALL_USERS,
  GET_ALL_GROUPS,
  HEADER_LOADING,
  GET_ALL_USERS_DETAILS,
  GET_WEEKLY_SIGNUP,
  GET_ALL_ONLINE,
  GRAPHLOADING,
} from "../../Constants/constants";
import ls from "localstorage-slim";

export const _ADMIN_LOGIN = async (objData) => {
  try {
    let { data } = await getApi(
      `${process.env.REACT_APP_API_KEY}user/signin`,
      objData
    );
    if (data.isSuccess) {
      return Promise.resolve({ data: data });
    } else {
      return Promise.resolve({ status: false });
    }
  } catch ({ message }) {
    return Promise.reject({ status: false, message });
  }
};

//get API

export const GET_USERS = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: HEADER_LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY}user?page=1&limit=1000000000`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: HEADER_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_ALL_USERS,
          payload: data,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: HEADER_LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: HEADER_LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_USER_BY_ID = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY}user/detail/${props}`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: GET_ALL_USERS_DETAILS,
          payload: data,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_GROUPS = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: HEADER_LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY}group/all?page=1&limit=${props}`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: GET_ALL_GROUPS,
          payload: data,
        });
        dispatch({
          type: HEADER_LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: HEADER_LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: HEADER_LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_BET_BY_GROUPID = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY}bet/all/${props}?page=1&limit=2`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_PRIVACY_POLICY = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY_PUBLIC}page/privacy-policy`
      );
      if (data.isSuccess) {
        localStorage.setItem("policy", JSON.stringify(data?.data?.body));
        dispatch({
          type: LOADING,
          payload: false,
        });
        dispatch({
          type: GET_PRIVACY_POLICYS,
          payload: data,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_TERM_CONDITION = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY_PUBLIC}page/term-condition`
      );
      if (data.isSuccess) {
        localStorage.setItem("terms", JSON.stringify(data?.data?.body));
        dispatch({
          type: LOADING,
          payload: false,
        });
        dispatch({
          type: GET_TERMS_CONDITION,
          payload: data,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_SETTINGS = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY}setting`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        dispatch({
          type: GET_ALL_SETTINGS,
          payload: data,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_WEEKLY_DATA = (type) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GRAPHLOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY}log/signup?filter=${type}`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: GET_WEEKLY_SIGNUP,
          payload: data,
        });
        dispatch({
          type: GRAPHLOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: GRAPHLOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: GRAPHLOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const GET_ONLINE_USERS = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: HEADER_LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await getApi(
        `${process.env.REACT_APP_API_KEY}user/online`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: GET_ALL_ONLINE,
          payload: data,
        });
        dispatch({
          type: HEADER_LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: HEADER_LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: HEADER_LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

//post API

export const POST_PRIVACY_POLICY = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await postApi(
        `${process.env.REACT_APP_API_KEY}page`,
        objdata,
        token?.access_token
      );
      if (data.isSuccess) {
        localStorage.setItem("policy", JSON.stringify(data?.data?.body));
        dispatch({
          type: GET_PRIVACY_POLICYS,
          payload: data,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const POST_TERM_CONDITION = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await postApi(
        `${process.env.REACT_APP_API_KEY}page`,
        objdata,
        token?.access_token
      );
      if (data.isSuccess) {
        localStorage.setItem("terms", JSON.stringify(data?.data?.body));
        dispatch({
          type: GET_TERMS_CONDITION,
          payload: data,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const POST_SETTINGS = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await postApi(
        `${process.env.REACT_APP_API_KEY}setting`,
        objdata,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: GET_ALL_SETTINGS,
          payload: data,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const POST_NOTIFICATION = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await postApi(
        `${process.env.REACT_APP_API_KEY}user/notification/send`,
        objdata,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

//put API

export const PUT_UPDATE_USER = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await putApi(
        `${process.env.REACT_APP_API_KEY}user`,
        objdata,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false, message: data?.message });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

//delete API

export const BLOCK_USER = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await deleteApi(
        `${process.env.REACT_APP_API_KEY}user/${objdata}`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ status: false, message: data?.message });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const DELETE_GROUP = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await deleteApi(
        `${process.env.REACT_APP_API_KEY}group/${objdata}`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({
          data: data,
        });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const FORCE_DELETE_GROUP = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await deleteApi(
        `${process.env.REACT_APP_API_KEY}group/${objdata}?force=true`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({
          data: data,
        });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const DELETE_USER = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await deleteApi(
        `${process.env.REACT_APP_API_KEY}user/${objdata}`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({
          data: data,
        });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const FORCE_DELETE_USER = (objdata) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const token = ls.get("Token", { decrypt: true });
      let { data } = await deleteApi(
        `${process.env.REACT_APP_API_KEY}user/${objdata}?force=true`,
        token?.access_token
      );
      if (data.isSuccess) {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return Promise.resolve({
          data: data,
        });
      }
    } catch ({ message }) {
      dispatch({
        type: LOADING,
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};
