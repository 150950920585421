import * as React from "react";
import { Button } from "reactstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import downloadCsv from "download-csv";

const GroupExport = ({ data }) => {
  const exportFileName = "UserData.csv";

  const downloadArrayData = () => {
    const datas = data?.map((row) => {
      return {
        Group_Id: row.id,
        Group_Title: row.title,
        Group_Points: row.points,
        Group_won: row.won,
        Group_Lost: row.lost,
        Group_Members: row.members.length,
        Group_Created_On_Date: moment(row.createdOnDate).format("MM/DD/YYYY"),
      };
    });
    const newdatas = data[0]?.members?.map((row) => {
      return {
        Member_Id: row.id,
        Member_FirstName: row.firstName,
        Member_LastName: row.lastName,
        Member_Points: row.points,
        Member_won: row.won,
        Member_Lost: row.lost,
        Member_Created_On_Date: moment(row.createdOnDate).format("MM/DD/YYYY"),
      };
    });
    const newDataarry = [...datas, ...newdatas];
    downloadCsv(newDataarry, exportFileName);
  };
  return (
    <>
      <Button
        title="Export Excel"
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary mb-2"
        onClick={() => downloadArrayData()}
      >
        Download GroupData
      </Button>
    </>
  );
};

export default GroupExport;
