import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import { UserContext } from "./context/userContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Provider } from "react-redux";
import { store } from "./state/store";
import axios from "axios";
import ls from "localstorage-slim";
import { refreshTokenApi } from "./state/action-creater/refreshTokenAction";

export default function App() {
  const token = ls.get("Token", { decrypt: true });
  const [user, setUser] = useState(token);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        refreshTokenApi();
      }
      return Promise.reject(error);
    }
  );

  // hide console!
  // console.log = console.warn = console.error = () => {};
  // console.error("Something bad happened.");

  return (
    <>
      <Provider store={store}>
        <UserContext.Provider value={{ user, setUser }}>
          <Switch>
            {user == null ? (
              <AuthLayout />
            ) : (
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
            )}
            <Redirect from="/" to="/admin/index" />
          </Switch>
        </UserContext.Provider>
      </Provider>
    </>
  );
}
