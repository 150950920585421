import React, { useState, useEffect } from "react";
// reactstrap components
import { Col, Container } from "reactstrap";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../../state/index";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import parse from "html-react-parser";

export default function PublicPrivacyPolicy() {
  const dispatch = useDispatch();
  const newpolicyData = useSelector((state) => state.allAuth.policyData);
  const isLoading = useSelector((state) => state.allAuth.isLoading);

  const { GET_PRIVACY_POLICY } = bindActionCreators(actionsCreators, dispatch);

  useEffect(() => {
    GET_PRIVACY_POLICY();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="aling-items-center mt-5">
          <Loader type="Grid" color="#8DBF26" height={60} width={60} />
        </div>
      ) : (
        <Container>
          <Col lg="12" md="12">
            <h1>{newpolicyData?.data?.title}</h1>
            {newpolicyData?.length !== 0 ? (
              <div className="ml-1"> {parse(newpolicyData?.data?.body)}</div>
            ) : (
              ""
            )}
            {/* {newpolicyData?.data?.body} */}
            {/* <div> {parse(newpolicyData?.data?.body)}</div> */}
          </Col>
        </Container>
      )}
    </>
  );
}
