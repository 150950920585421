export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const LOADING = "LOADING";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_PRIVACY_POLICYS = "GET_PRIVACY_POLICYS";
export const GET_TERMS_CONDITION = "GET_TERMS_CONDITION";
export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const HEADER_LOADING = "HEADER_LOADING";
export const GET_ALL_USERS_DETAILS = "GET_ALL_USERS_DETAILS";
export const GET_WEEKLY_SIGNUP = "GET_WEEKLY_SIGNUP";
export const GET_ALL_ONLINE = "GET_ALL_ONLINE";
export const GRAPHLOADING = "GRAPHLOADING";
